<template>
    <div>
        <!-- Page Title Section -->
        <section class="page-title">
            <div class="pattern-layer-one" :style="{backgroundImage: 'url('+bgPattern5+')'}"></div>
            <div class="pattern-layer-two" :style="{backgroundImage: 'url('+bgPattern6+')'}"></div>
            <div class="icon-layer-one" :style="{backgroundImage: 'url('+icon5+')'}"></div>
            <div class="icon-layer-two" :style="{backgroundImage: 'url('+icon5+')'}"></div>
            <div class="icon-layer-three" :style="{backgroundImage: 'url('+icon6+')'}"></div>
            <div class="icon-layer-four" :style="{backgroundImage: 'url('+iconPattern1+')'}"></div>
            <div class="icon-layer-five" :style="{backgroundImage: 'url('+iconPattern2+')'}"></div>
            <div class="auto-container">
                <h1>Privacy Policy</h1>
                <ul class="page-breadcrumb">
                    <li>
                        <router-link :to="{name: 'Home'}">home</router-link>
                    </li>
                    <li>Privacy Policy</li>
                </ul>
            </div>
        </section>
        <!-- End Page Title Section -->

        <!-- Privacy Section -->
        <section class="privacy-section" style="margin-bottom: 170px">
            <div class="auto-container">
                <!-- Privacy Content -->
                <div class="privacy-content">
                    <h2>Privacy Policy</h2>
                    <p>
                        Our company understands the importance of personal information of every participant. Cooperating with our company, you can be sure in privacy of your personal information and in its protection by our employees. Our employees protect the collected by them information from unauthorized access. We use a variety of technologies to reduce the risk of theft of accounts and receiving personal information of our investors.
                    </p>
                    <div class="date">Updated January 6, 2020</div>
                    <p>
                        "Personal information" includes such items as;
                    </p>
                    <ul class="privacy-list">
                        <li>Name and Last Name of the investor</li>
                        <li>Email of investor</li>
                        <li>Contact address of investor</li>
                    </ul>
                    <p>
                        Our company collects your personal information only with your consent and confidence in the security of your personal information on our project. Users should note, that the collection of personal information is only from adult. The rules of our company explain, how your information is collected and used. These rules apply only in our website. Information which is collected when you visit this site. This types of information are also the part of the personal information. Customer information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without the consent of the customer, other than for the express purpose of delivering the purchased product or service requested by the customer. For your safety and protection, your credit card information is not stored on our servers. Our payment gateway provider, (insert providers name/s here), keeps this information encrypted and secure on your behalf. The email address you provide for order processing, may be used to send you information and updates pertaining to your account, in addition to periodic company news, updates, and/or related product or service information, etc. If you decide to opt-in to our mailing list, you will receive emails that may include company news, updates, related product or service information, promotions, etc. However, you may opt-out at any time by (please enter the opt-out or unsubscribe process here. This may be something like clicking the unsubscribe button at the bottom of your emails.
                    </p>
                </div>
                <!-- Privacy Content -->
                <div class="privacy-content">
                    <h4>Information Storage</h4>
                    <p>
                        Saving of information is only in our company or its equipment. Personal information is stored in accordance with the rules of storage and disposal, which are set for archive of our company. To receive your personal information, contact direct with our employees.
                    </p>
                </div>
                <!-- Privacy Content -->
                <div class="privacy-content">
                    <h4>Contact Information</h4>
                    <p>
                        TrustEarn is glad to receive your comments concerning this "Privacy Policy". In case you think we do not follow the rules and violate this Statement, contact us at
                        <a href="mailto:trustearn@gmail.com">trustearn@gmail.com</a> We guarantee that we will take commercially reasonable efforts to discover and solve the problem.
                    </p>
                </div>
            </div>
        </section>
        <!-- End Privacy Section -->

    </div>
</template>

<script>
import icon1 from "../../assets/images/icons/icon-1.png";
import icon2 from "../../assets/images/icons/icon-2.png";
import icon3 from "../../assets/images/icons/icon-3.png";
import icon4 from "../../assets/images/icons/icon-4.png";
import bgPattern5 from "../../assets/images/background/pattern-5.png";
import bgPattern6 from "../../assets/images/background/pattern-6.png";
import icon5 from "../../assets/images/icons/icon-5.png";
import icon6 from "../../assets/images/icons/icon-6.png";
import iconPattern1 from "../../assets/images/icons/pattern-1.png";
import iconPattern2 from "../../assets/images/icons/pattern-2.png";

export default {
    name: "Privacy",
    data(){
        return {
            icon1, icon2, icon3, icon4,
            bgPattern5, bgPattern6,
            icon5, icon6, iconPattern1,
            iconPattern2
        }
    }
}
</script>

<style scoped>

</style>